<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="text-md-right">
              <router-link
                  :to="{ name: 'admin.plans.create' }"
                  class="btn btn-dark-blue btn-sm mr-4"
              >
                <i class="uil uil-plus"></i> New Plan
              </router-link>
            </div>
            <template v-if="!loadingPlans">
              <div class="table-responsive mb-0">
                <b-table
                    :items="plans"
                    :fields="datatable.columns"
                    :sort-by.sync="datatable.queries.sort_by"
                    :sort-desc.sync="datatable.queries.sort_desc"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(name)="data">
                    <span v-b-tooltip.hover v-b-tooltip.hover.right :title="data.item.desc">{{ data.item.name }}</span>
                  </template>
                  <template v-slot:cell(type)="data">
                    <div v-if="data.item.type === 'one_time'">
                      Onetime
                    </div>
                    <div v-else-if="data.item.recurring.interval === 'year' && data.item.recurring.interval_count === 1">
                      Yearly
                    </div>
                    <div v-else-if="data.item.recurring.interval === 'month' && data.item.recurring.interval_count === 1">
                      Monthly
                    </div>
                    <div v-else>
                      <span>Interval: {{data.item.recurring.interval}}</span>
                      <div>Interval Count: {{data.item.recurring.interval_count}}</div>
                    </div>
                  </template>
                  <template v-slot:cell(features)="data">
                    <div v-for="item of data.item.features" :key="item.key">
                      <p
                          v-if="
                          item.key !== 'Sales Plan' ||
                            (item.key === 'Sales Plan' && item.value)
                        "
                          class="mb-1"
                      >
                        {{ item.key }}: {{ item.value }}
                      </p>
                    </div>
                  </template>
                  <template v-slot:cell(salesplan)="data">
                    <span>
                      {{ data.item.sale_plan }}
                    </span>
                  </template>
                  <template  v-slot:cell(has_trial)="data">
                    <feather
                        v-if="data.item.trial_period_days"
                        type="check"
                        class="icon-dual-success"
                    />
                  </template>
                  <template v-slot:cell(actions)="data">
                    <b-button
                        variant="link"
                        title="Link"
                        class="ml-1 btn btn-action"
                        @click="showLink(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-copy"></i>
                    </b-button>
                    <router-link
                        :to="{
                        name: 'admin.plans.edit',
                        params: { id: data.item.id },
                      }"
                        class="btn btn-link btn-action text-success ml-1"
                    >
                      <i class="uil uil-edit"></i>
                    </router-link>
                    <b-button
                        v-if="false"
                        variant="link"
                        title="Delete"
                        class="ml-1"
                        :disabled="loadingRemove === data.item.id"
                        @click="remove(data.item)"
                    >
                      <b-spinner v-if="loadingRemove === data.item.id" small />
                      <i v-else class="uil uil-trash"></i>
                    </b-button>
                  </template>
                  <template v-slot:cell(visible)="data">
                    <feather
                        v-if="data.item.visible"
                        type="check"
                        class="icon-dual-success"
                    />
                  </template>
                </b-table>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showDeleteModal" title="Delete plan">
      <p v-if="selectedPlan"
      >Are you sure you want to delete plan "{{ selectedPlan.name }}"?</p
      >
      <template v-slot:modal-footer>
        <b-button variant="light" @click="showDeleteModal = false"
        >Cancel</b-button
        >
        <b-button variant="danger" @click="handleDelete">Delete</b-button>
      </template>
    </b-modal>
    <b-modal v-model="showScriptModal" title="Script" size="lg">
      <h3>Here's the order page link for the select plan.</h3>
      <div>
        <textarea
            ref="linkText"
            v-model="orderLink"
            name="code"
            cols="30"
            rows="6"
            class="form-control"
            readonly
            @click="copyLink"
        ></textarea>
        <div class="text-right mt-1">
          <a href="javascript:void(0)" @click="copyLink">
            Copy the code
          </a>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="primary" class="btn-dark-blue" @click="copyLink"
        ><i class="uil uil-copy"></i> Copy</b-button
        >
        <b-button variant="light" @click="showScriptModal = false"
        >Cancel</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  data() {
    return {
      datatable: {
        columns: [
          { key: 'name', sortable: true },
          { key: 'amount', label: 'Price', sortable: true },
          { key: 'type', label: 'Duration' },
          { label: 'Features', key: 'features' },
          { label: 'Sales Plan', key: 'salesplan' },
          { label: 'Has Trial', key: 'has_trial'},
          { label: 'Visible', key: 'visible' },
          { key: 'actions', class: 'actions' },
        ],
        queries: {
          sort_by: 'id',
          sort_desc: false,
        },
      },
      showDeleteModal: false,
      selectedPlan: null,
      loadingPlans: false,
      loadingImpersonate: false,
      loadingRemove: null,
      showScriptModal: false,
      orderLink: '',
    }
  },

  computed: {
    plans() {
      return this.$store.getters['subscription/allPlans'] || []
    },
  },

  created() {
    this.$store.dispatch('agency/setCurrentPage', 'index')
  },

  mounted() {
    if (!this.$store.getters['subscription/allPlans']) {
      this.getPlans()
    }
  },

  methods: {
    showLink(plan) {
      this.orderLink = `${location.origin}/order?plan=${plan.id}`;
      this.showScriptModal = true
    },

    copyLink() {
      this.$refs.linkText.select()
      this.$refs.linkText.setSelectionRange(0, 999999)
      document.execCommand('copy')

      Vue.$toast.open({
        message: 'Copied',
        type: 'success',
        duration: 5000,
      })
    },

    getPlans() {
      if (this.$store.getters['subscription/allPlans']) return

      this.loadingPlans = true
      this.$store
          .dispatch('subscription/getAllPlans')
          .then(() => {
            this.loadingPlans = false
          })
          .catch(() => {
            this.loadingPlans = false
          })
    },

    remove(plan) {
      this.showDeleteModal = true
      this.selectedPlan = plan
    },

    handleDelete() {
      this.showDeleteModal = false
      this.loadingRemove = this.selectedPlan.id
      this.$store
          .dispatch('subscription/deletePlan', this.selectedPlan.id)
          .then(() => {
            this.loadingRemove = null
          })
          .catch(() => {
            this.loadingRemove = null
          })
    },
  },
}
</script>
